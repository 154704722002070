small {
  font-size: 55% !important;
}
a:hover {
  color: $blue !important;
}
a,
a.metismenu-link:hover {
  color: $primary !important;
}


#export__option {
  display: flow-root;
  z-index: 1000 !important;
  padding: 0 0 10px 0;
}

#export__option > .export__pdf {
  float: right !important;
}


div[hidden] {
  display: none !important;
}

#datasets, #overview, #members, #regions, #tco_users, #tco_usage_report, #kp_article, #kp_members, #kp_search{
  padding: 5px;
}

.print__modal__body {
  text-align: center;
  align-self: center;
}

.print__modal__body{
  i{
    font-size: 5.5rem !important;
  }
}
.print__modal-footer {
  font-size: 1.4rem;
  place-self: center !important ;
  padding: 0 0 15px 0;
}

.custom_loading{
 
  * {
    box-sizing: border-box;
  }
  
  .s2 {
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: transparent;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .s1 {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: transparent;
  }
  
  .bigcon {
    position: absolute;
    height: 95px;
    width: 95px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: transparent;
    animation: bigcon 2s infinite linear;
    animation-delay: 0.25s;
  }
  
  .b {
    border-radius: 50%;
    position: absolute;
  }
  
  .s {
    width: 25px;
    height: 25px;
    animation: small 2s infinite ease;
    box-shadow: 0px 2px rgba(0,0,0,0.3);
     background-color: #46b9ff;
  }
  
  .s:nth-child(1) {
    top: 0%;
    left: 0%;
  }
  
  .s:nth-child(2) {
    top: 0%;
    right: 0%;
  }
  
  .s:nth-child(3) {
    right: 0%;
    bottom: 0%;
  }
  
  .s:nth-child(4) {
    bottom: 0%;
    left: 0%;
  }
  
  .big {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    box-shadow:0px 0px 10px #54f7f8, 0px 0px 20px #54f7f8, 0px 0px 30px #54f7f8, 0px 0px 50px #54f7f8, 0px 0px 60px #54f7f8 ;
    z-index: 1;
    background-color: #54f7f8;
    animation: bigball 1s infinite linear;
  }
  
  .sb1{
    animation-delay: -1.75s;
  }
  .sb6{
    animation-delay: -1.5s;
  }
  .sb2{
    animation-delay: -1.25s;
  }
  .sb7{
    animation-delay: -1s;
  }
  .sb3{
    animation-delay: -0.75s;
  }
  .sb8{
    animation-delay: -0.5s;
  }
  .sb4{
    animation-delay: -0.25s;
  }
  .sb5{
    animation-delay: -0s;
  }
  
  @keyframes bigcon {
    0% {
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    100% {
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(405deg);
    }
  }
  
  @keyframes small {
    0% {
      transform: scale(1);
       background-color: #46b9ff;
    }
    10% {
      transform: scale(1.3);
      background-color: #54f7f8;
    }
    15% {
      transform: scale(1);
    }
    25%{
  transform: scale(1);
       background-color: #46b9ff;
    }
    100%{
      transform: scale(1);
       background-color: #46b9ff;
    }
  }
}